<template>
  <div class="home-banner">
    <div class="home-banner-content">
      <h2 data-aos="fade-right" data-aos-delay="50">Offering You The Best Hibachi Experience Ever IN</h2>
      <h2 data-aos="fade-left" data-aos-delay="300" class="bold">the northeastern corner.</h2>
      <h2 data-aos="fade-right" data-aos-delay="500">look no further, you’ve come to the right place.</h2>

      <div class="button-wrapper mt-10" v-if="isNotDesktop">
        <!-- <CustomButton 
          text="EXPLORE MENU"
          :isWhite="true"
          :isMenu="true"
        /> -->
        <CustomButton 
          class="ml-2"
          text="DOORDASH ORDER"
          :isWhite="true"
          :isMenu="true"
          :link="'https://www.doordash.com/store/kazoku-hibachi-express-jonesboro-24912729/'"
        />
        <!-- <CustomButton
          class="ml-3"
          text="ABOUT US"
          :link="'/about'"
          :isWhite="true"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'

export default {
  name: 'HomeBanner',
  components: {
    CustomButton
  },
  computed: {
    isNotDesktop () {
      return window.innerWidth < 1025
    },
  },
}
</script>


<style scoped>
.home-banner {
  height: 100vh;
  width: 100%;
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/home-page-banner-bg.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}

.home-banner-content {

}
h2 {
  font-family: 'Oswald', sans-serif !important;
  text-transform: uppercase;
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
}
.bold {
  font-weight: 700;
}

.button-wrapper {
  justify-content: center;
  display: flex;
  width: 100%;
}


@media screen and (min-width: 600px) {
  .home-banner-content {
    padding: 0 32px;
  }
  h2 {
    font-size: 32px !important;
  }
}

@media screen and (max-width: 599px) {
  h2 {
    font-size: 24px !important;
  }
}
</style>
