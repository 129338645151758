<template>
  <div class="footer-wrapper px-10">
    <v-container>
      <v-row class="d-flex justify-center align-center">
        <v-col :cols="12" :sm="isMobile ? 12 : 6">
          <div :class="{ 'text-left': isMobile, 'text-left mr-15': !isMobile }">
            <img src="@/assets/location-header.png" alt="" width="150px" />
            <div class="address mt-5">
              <b> JONESBORO, ARKANSAS </b> <br />
              2411 Race St. Suite A
              <br />
              <img
                src="@/assets/resto-pic.jpeg"
                alt=""
                width="250px"
                class="rounded-lg"
              />
              <br />
              <br />

              <b>OPENING HOURS</b> <br />
              Monday - Saturday <br />
              11.00 - 14.30 <br />
              16.30 - 21.00 <br />
              Sunday - CLOSED <br /><br />

              <b>CONTACT US</b> <br />
              <div class="button-wrapper mt-2">
                <v-btn
                  class="mx-2 ml-0"
                  fab
                  dark
                  color="dark"
                  @click="handleClick('phone')"
                >
                  <v-icon dark> mdi-phone </v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  color="dark"
                  @click="handleClick('instagram')"
                >
                  <v-icon dark> mdi-instagram </v-icon> </v-btn
                ><v-btn
                  class="mx-2"
                  fab
                  dark
                  color="dark"
                  @click="handleClick('facebook')"
                >
                  <v-icon dark> mdi-facebook </v-icon>
                </v-btn>
              </div>
              <br />
            </div>
          </div>
        </v-col>
        <v-col :cols="12" :sm="isMobile ? 12 : 6">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                class="rounded-lg"
                height="380"
                id="gmap_canvas"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3235.4527999988104!2d-90.67903248780803!3d35.81336472258837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d42c1d6e905cd1%3A0xe6763003b250fef!2s2411%20Race%20St%2C%20Jonesboro%2C%20AR%2072401%2C%20USA!5e0!3m2!1sen!2sid!4v1688709135813!5m2!1sen!2sid"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              >
              </iframe>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
  },
  methods: {
    handleClick(type) {
      if (type === "phone") {
        window.location.href = "tel:+1(870)3168557";
      } else if (type === "instagram") {
        const url = "https://www.instagram.com/kazoku.express/";
        window.open(url);
      } else {
        const url =
          "https://www.facebook.com/people/Kazoku-Hibachi-Jonesboro/100062956763694/";
        window.open(url);
      }
    },
  },
};
</script>

<style scoped>
.footer-wrapper {
  padding: 100px 0;
  /* padding: 142px 0; */
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/footer-bg-2.jpg'); */
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/footer-bg-half.png'); */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://media.istockphoto.com/id/1630278915/photo/chef-cooking-in-japanese-teppanyaki-restaurant.jpg?b=1&s=612x612&w=0&k=20&c=K-V3PUXF3fTxY8nX6i64u0iURyyjy1U2F2oRxsqeaKM=");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-family: "Inter", sans-serif !important;
  border-top: solid white 2px;
}
iframe {
  width: 100%;
}
.maps-button {
  width: 200px;
  text-transform: uppercase;
}

#gmap_canvas {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}
@media screen and (max-width: 599px) {
  .address {
    font-size: 15px;
  }
}
</style>
