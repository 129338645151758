<template>
  <div class="news-section">
    <TitleSection :title="'What they said'" />
      <!-- autoplay -->
    <vueper-slides
      autoplay
      infinite
      class="no-shadow"
      :visible-slides="isTablet ? 2 : 2"
      slide-multiple
      :gap="0"
      :slide-ratio="1 / 4"
      :dragging-distance="200"
      :bullets="false"
      :arrows="true"
      :breakpoints="{ 
        800: { 
          visibleSlides: 2, 
          slideMultiple: 2 
        },
        600: { 
          visibleSlides: 1, 
          slideMultiple: 1 
        },

      }"
      :fixed-height="isMobile ? '300px' : '450px'"
    >
      
      <vueper-slide 
        v-for="(i, index) in testimonyContent" 
        :key="index" 
        :image="i.image" 
        :content="i.content"
      />
      
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { testimonyContent } from '../utils/testimony'
import TitleSection from '@/components/TitleSection.vue'

export default {
  name: 'GallerySection',
  components: { 
    VueperSlides, 
    VueperSlide,
    TitleSection
  },
  computed: {
    isMobile () {
      return window.innerWidth < 600
    },
    isTablet () {
      return window.innerWidth < 1280
    },
  },
  data () {
    return {
      testimonyContent
    }
  },
}
</script>

<style>
.vueperslide__content-wrapper:first-child {
  border-left: white solid 1px;
  border-right: white solid 1px;
  /* border-bottom: white solid 2px; */
  color: white;
}

.vueperslides--fixed-height.vueperslides--bullets-outside {
  margin: 0 !important;
}
.vueperslide__title {
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}
.vueperslide__image {
  opacity: 0.5;
  background-color: black;
}
.vueperslide vueperslide--active vueperslide--visible {

  opacity: 0.5;
  background-color: black;
}

  @media screen and (max-width: 599px) {
    /* .vueperslides--fixed-height { 
      height: 470px; 
    }
  }

  @media screen and (min-width: 600px) {
    .vueperslides--fixed-height { 
      height: 800px; 
    } */
    .vueperslides__arrow svg {
      width: 2.5em;
      padding: 1em;
    }
    .testi-text-wrapper {
      padding: 2.2rem !important;
    }
    b {
      font-size: 15px !important; 
    }
  }

@media screen and (max-width: 1280px) {
  .news-wrapper {
    flex-wrap: wrap;
  }
  .news-content {
    flex: 0 0 50%;
  }
}
</style>