<template>
  <a 
    class="button-custom" 
    @click="goTo(link)" 
    :class="{
      'block': isBlock, 
      'white-btn': isWhite, 
      'is-catering': isCatering
      }">
    <div 
      v-if="isWhite"
      class="button-text" 
      :class="isWhite ? 'white-text' : ''">{{ text }}
    </div>

    <div 
      v-else
      class="button-text" 
      :class="isCatering ? 'white-text' : ''">{{ text }}
    </div>
  </a>
</template>

<script>
export default {
  name: 'CustomButton',
  props: ['text', 'link', 'isBlock', 'isWhite', 'isMenu', 'isCatering'],
  methods: {
    goTo (link) {
      if (this.isMenu) {
        if (link) {
          window.open(link)
        } else {
          window.open('https://drive.google.com/file/d/1_pHf8LfcwREj6mmgS___dLXesF4qnYWy/view?usp=drive_link')
        }
      } else if (this.isCatering) {
        if(this.$route.name === 'gallery') {
          window.open('https://drive.google.com/file/d/1_pHf8LfcwREj6mmgS___dLXesF4qnYWy/view?usp=drive_link')
        } else {
          window.open('https://drive.google.com/file/d/1kWxUfT0j29KSLOg18_j45ee3uDMvKvjv/view?usp=drive_link')
        }
      } else {
        if (this.$route.path !== link) this.$router.push(link)
      }
    },
  }
}
</script>

<style>

.button-custom {
  cursor: pointer;
  border: #121212 solid 2px;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.block {
  height: 70px;
  width: 100% !important;
  font-size: 20px;
}

.button-text {
  font-weight: 600;
  color: #121212;
  text-transform: uppercase;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.button-custom:hover {
  background-color: #121212;
  border: white solid 2px;
}

.button-custom:hover .button-text { 
  color: white;
}

.white-btn {
  border: white solid 2px;
  background: none;
}
.white-text {
  color: white;
}
.white-btn:hover {
  background-color: #121212;
  border: white solid 2px;
}

.is-catering {
  border: white solid 2px;
  background: none;
  color: white !important;
}

.is-catering:hover {
  background-color: white;
}
.is-catering:hover .button-text {
  color: black !important;

}

@media screen and (min-width: 600px) {
  /* .button-text {
    font-size: 12px;
  } */
}

@media screen and (max-width: 599px) {
  .button-text {
    font-size: 15px;
    font-weight: 900;
  }
  .button-custom {
    width: 180px;
  }
}
</style>