import image2 from '@/assets/foods/darken/darken-1.png'
import image4 from '@/assets/foods/darken/darken-1.png'
import image9 from '@/assets/foods/darken/darken-1.png'

export const testimonyContent = [{
    image: image9,
    content: `<div class="testi-text-wrapper" style="padding: 3rem;"><b class="testi-text" style="font-size: 1.2rem;color: #fff">"Prepared our catering order with two days notice.
    Responded promptly on Messenger. Offered cell # to ensure I could communicate about my order timely.
    Showed up on time for delivery. Food was prepared as described. They were very thankful for my business. I will order again in the future."</b></div>`,
  },
  {
    image: image2,
    content: `<div class="testi-text-wrapper" style="padding: 3rem;"><b class="testi-text" style="font-size: 1.2rem;color: #fff">"a HUGE thank you for our catering order! their food was fantastic and the customer service was unprecedented! thank you for an amazing experience!
    if you're considering ordering, do it! we had 40 happy people!"</b></div>`,
  },
  {
    image: image4,
    content: `<div class="testi-text-wrapper" style="padding: 3rem;"><b class="testi-text" style="font-size: 1.2rem;color: #fff">"Everyone loved the food that was catered.
    Everything was so fresh and delicious as always. Love the staff!!"</b></div>`,
  },
  {
    image: image4,
    content: `<div class="testi-text-wrapper" style="padding: 3rem;"><b class="testi-text" style="font-size: 1.2rem;color: #fff">"We had a great time hosting and welcoming the new businesses and members that are part of the wonderful Jonesboro Regional Chamber of Commerce! The new member luncheon served as a informational lunch on how to get involved with the community through our committees!
    Thank you Kazoku Hibachi Jonesboro for our delicious lunch and congratulations to our door prize winner!"</b></div>`,
  }
]