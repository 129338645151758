<template>
  <div class="navbar-wrapper">
    <div class="navbar" v-if="!isMobile">
      <img
        src="@/assets/logo-white.png"
        height="70"
        width="150"
        draggable="false"
        @click="goTo('/')"
        class="logo mt-2 mb-1"
      />
      <div class="tabs-container mt-2">
        <!-- <a class="nav-wrapper" @click="goToSection('news')"><div>WHAT'S ON</div></a> -->
        <a class="nav-wrapper" @click="goTo('/about')"><div>ABOUT</div></a>
        <!-- <a class="nav-wrapper" @click="goTo('/about')"><div>MENUS</div></a> -->

        <v-menu open-on-hover top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> Menus </v-btn> -->
            <a class="nav-wrapper" v-bind="attrs" v-on="on"><div>MENUS</div></a>
          </template>

          <v-list>
            <a @click="goTo('/lunchMenu')">
              <v-list-item>
                <v-list-item-title>Lunch Menu</v-list-item-title>
              </v-list-item>
            </a>
            <a @click="goTo('/dinnerMenu')">
              <v-list-item>
                <v-list-item-title>Dinner Menu</v-list-item-title>
              </v-list-item>
            </a>
            <a @click="goTo('/cateringMenu')">
              <v-list-item>
                <v-list-item-title>Catering Menu</v-list-item-title>
              </v-list-item>
            </a>
          </v-list>
        </v-menu>
        <a class="nav-wrapper" @click="goTo('/catering')"
          ><div>CATERING</div></a
        >
        <a class="nav-wrapper" @click="goTo('/gallery')"><div>GALLERY</div></a>
        <a
          class="nav-wrapper"
          target="_blank"
          href="https://www.google.com/search?q=kazoku+hibachi+express+jonesboro&sca_esv=4459457999b0ab68&sca_upv=1&sxsrf=ADLYWIKlOz8Z4WmMTL9YybR8zBxplU2r3Q%3A1716613652248&ei=FHJRZrvJDqTh4-EP68eL4A4&oq=kazoku+hibachi+jones&gs_lp=Egxnd3Mtd2l6LXNlcnAiFGthem9rdSBoaWJhY2hpIGpvbmVzKgIIATIFEAAYgAQyBhAAGBYYHjIGEAAYFhgeMgsQABiABBiGAxiKBTILEAAYgAQYhgMYigUyCxAAGIAEGIYDGIoFMggQABiABBiiBDIIEAAYogQYiQUyCBAAGIAEGKIEMggQABiABBiiBEjeKFDxEljHGnABeAGQAQCYAZABoAHcBKoBAzQuMrgBA8gBAPgBAZgCB6AC9gTCAgoQABiwAxjWBBhHwgIKEAAYgAQYQxiKBcICEBAuGIAEGEMYxwEYigUYrwGYAwCIBgGQBgiSBwM1LjKgB6Es&sclient=gws-wiz-serp#ip=1"
          ><div>REVIEW</div></a
        >
        <a class="nav-wrapper" @click="goTo('/contact')"
          ><div>CONTACT</div></a
        >
        <!-- <a class="nav-wrapper" @click="goTo('/order')"><div>ORDER</div></a> -->
      </div>
    </div>

    <!-- MOBILE NAV -->
    <div class="mobile-navbar px-5" v-else>
      <img
        src="@/assets/logo-white.png"
        height="40"
        width="90"
        draggable="false"
        @click="goTo('/')"
        class="logo-mobile mt-5 mb-4 mr-10"
      />
      <v-spacer></v-spacer>

      <v-app-bar-nav-icon @click="openMenu" color="white"></v-app-bar-nav-icon>
    </div>

    <v-bottom-sheet v-model="sheet" inset fullscreen>
      <v-sheet class="nav-menu">
        <div class="menu-bar">
          <v-spacer></v-spacer>

          <v-btn
            text
            icon
            color="#121212"
            width="40"
            height="40"
            @click="sheet = !sheet"
          >
            <v-icon class="icon" size="40" color="#121212">mdi-close</v-icon>
          </v-btn>
          <!-- <v-btn icon @click="toggleCart" color="#121212" width="40">
            <v-icon>mdi-cart-outline</v-icon>
          </v-btn> -->
          <!-- <div v-if="cart.length" class="notification-badge">{{ cart.length }}</div> -->
        </div>
        <v-divider></v-divider>
        <div class="menu-item mt-3 mb-3" @click="sheet = !sheet">
          <a @click="goTo('/')"> HOME </a>
        </div>

        <div class="menu-item mb-3" @click="sheet = !sheet">
          <a @click="goTo('/about')"> ABOUT </a>
        </div>

        <div class="menu-item mb-3" @click="sheet = !sheet">
          <a @click="goTo('/contact')"> CONTACT </a>
        </div>

        <div class="menu-item mb-3" @click="sheet = !sheet">
          <a @click="goTo('/lunchMenu')"> LUNCH MENU </a>
        </div>
        <div class="menu-item mb-3" @click="sheet = !sheet">
          <a @click="goTo('/dinnerMenu')"> DINNER MENU </a>
        </div>
        <div class="menu-item mb-3" @click="sheet = !sheet">
          <a @click="goTo('/cateringMenu')"> CATERING MENU </a>
        </div>
        <div class="menu-item mb-3" @click="sheet = !sheet">
          <a @click="goTo('/catering')"> CATERING </a>
        </div>
        <div class="menu-item mb-3" @click="sheet = !sheet">
          <a @click="goTo('/gallery')"> GALLERY </a>
        </div>
        <div class="menu-item mb-3" @click="sheet = !sheet">
          <a
            target="_blank"
            href="https://www.google.com/search?q=kazoku+hibachi+express+jonesboro&sca_esv=4459457999b0ab68&sca_upv=1&sxsrf=ADLYWIKlOz8Z4WmMTL9YybR8zBxplU2r3Q%3A1716613652248&ei=FHJRZrvJDqTh4-EP68eL4A4&oq=kazoku+hibachi+jones&gs_lp=Egxnd3Mtd2l6LXNlcnAiFGthem9rdSBoaWJhY2hpIGpvbmVzKgIIATIFEAAYgAQyBhAAGBYYHjIGEAAYFhgeMgsQABiABBiGAxiKBTILEAAYgAQYhgMYigUyCxAAGIAEGIYDGIoFMggQABiABBiiBDIIEAAYogQYiQUyCBAAGIAEGKIEMggQABiABBiiBEjeKFDxEljHGnABeAGQAQCYAZABoAHcBKoBAzQuMrgBA8gBAPgBAZgCB6AC9gTCAgoQABiwAxjWBBhHwgIKEAAYgAQYQxiKBcICEBAuGIAEGEMYxwEYigUYrwGYAwCIBgGQBgiSBwM1LjKgB6Es&sclient=gws-wiz-serp#ip=1"
          >
            REVIEW
          </a>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  created() {},
  data() {
    return {
      sheet: false,
      cartDrawer: false,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 960;
    },
    isTablet() {
      return window.innerWidth < 1280;
    },
  },
  methods: {
    goTo(link) {
      if (this.$route.path !== link) this.$router.push(link);
    },
    toggleCart() {
      this.$refs.cart.toggle();
    },
    openMenu() {
      this.sheet = true;
    },
    goToSection(section) {
      if (this.$route.path === "/") {
        if (section === "gallery") {
          this.$router.push({ path: "/", hash: "#gallery" }).catch(() => {});
          if (this.isMobile) {
            window.scrollTo(0, 2275);
          } else if (this.isTablet) {
            window.scrollTo(0, 2459);
          } else {
            window.scrollTo(0, 1833);
          }
        } else if (section === "news") {
          this.$router.push({ path: "/", hash: "#news" }).catch(() => {});
          if (this.isMobile) {
            window.scrollTo(0, 1773);
          } else if (this.isTablet) {
            window.scrollTo(0, 1673);
          } else {
            window.scrollTo(0, 1400);
          }
        }
      } else {
        this.$router.push({ path: "/", hash: `#${section}` }).catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  color: white;
  position: fixed;
  z-index: 100;
  width: 99.79%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-navbar {
  color: white;
  position: fixed;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.v-bottom-sheet.v-dialog.v-bottom-sheet--inset {
  max-width: 100% !important;
}

.navbar.scrolled {
  background-color: #121212 !important;
  transition: background-color 500ms linear;
}

.mobile-navbar.scrolled {
  background-color: #121212 !important;
  transition: background-color 500ms linear;
}

.tabs-container {
  border-bottom: white solid 2px !important;
  border-top: white solid 2px !important;
  display: flex;
  width: 100%;
  padding: 0 15rem;
  justify-content: space-evenly;
}

.logo-mobile {
  cursor: pointer;
  width: 200px;
  height: 100%;
}
.logo {
  cursor: pointer;
  width: 250px;
  height: 100%;
}
.list-item {
  letter-spacing: 1px;
  font-size: 14px;
}
.notification-badge {
  position: absolute;
  right: 0;
  top: -15px;
  min-width: 20px;
  min-height: 10px;
  line-height: 10px;
  padding: 5px;
  color: #fff;
  background-color: indianred;
  font-size: 14px;
  border-radius: 50%;
}

.v-tabs-slider-wrapper {
  bottom: 0;
  margin: 0 !important;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  z-index: 1;
  display: none;
}

.nav-wrapper {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  color: white !important;
  font-weight: 600;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nav-wrapper:hover {
  color: black !important;
  background-color: white;
}

.nav-menu {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theme--light.v-sheet {
  color: #121212;
  background-color: white;
}

.menu-item {
  font-family: "Oswald", sans-serif !important;
  font-size: 32px;
  font-weight: 700;
  line-height: 50px;
  width: 100%;
  padding-left: 3rem;
}

a {
  color: #121212 !important;
  text-decoration: none;
}

.menu-bar {
  display: flex;
  width: 100%;
  padding: 1rem;
  align-items: center;
  border-bottom: #121212 solid 2px;
}

@media screen and (min-width: 600px) {
  .mobile-navbar {
    width: 99.482%;
  }
}

@media screen and (max-width: 599px) {
  .mobile-navbar {
    width: 99%;
  }
}
</style>
