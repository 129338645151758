var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-section"},[_c('TitleSection',{attrs:{"title":'What they said'}}),_c('vueper-slides',{staticClass:"no-shadow",attrs:{"autoplay":"","infinite":"","visible-slides":_vm.isTablet ? 2 : 2,"slide-multiple":"","gap":0,"slide-ratio":1 / 4,"dragging-distance":200,"bullets":false,"arrows":true,"breakpoints":{ 
      800: { 
        visibleSlides: 2, 
        slideMultiple: 2 
      },
      600: { 
        visibleSlides: 1, 
        slideMultiple: 1 
      },

    },"fixed-height":_vm.isMobile ? '300px' : '450px'}},_vm._l((_vm.testimonyContent),function(i,index){return _c('vueper-slide',{key:index,attrs:{"image":i.image,"content":i.content}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }