<template>
  <div class="footer-logo">
    <img
      src="@/assets/kazoku-logo.png"
      width="100"
      draggable="false"
    />
  </div>
</template>

<script>
export default {
  name: 'FooterLogo'
}
</script>

<style>
.footer-logo {
  background-color: #121212;
  padding: 8px 33px;
  text-align: -webkit-center;
  border-top: white solid 2px;
  border-bottom: white solid 2px;
}
</style>