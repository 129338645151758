<template>
  <div class="home-wrapper" @scroll="checkH">
    <HomeBanner />
    <HomeIntro />
    <GallerySection />
    <TestimonySection />
  </div>
</template>

<script>
  import HomeBanner from '@/components/HomeBanner.vue'
  import HomeIntro from '@/components/HomeIntro.vue'
  import TestimonySection from '@/components/Testimony.vue'
  import GallerySection from '@/components/GallerySection.vue'
  export default {
    name: 'HomePage',
    components: {
      HomeBanner,
      HomeIntro,
      TestimonySection,
      GallerySection
    },
    computed: {
      isMobile () {
        return window.innerWidth < 960
      },
      isTablet () {
        return window.innerWidth < 1280
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from
      })
    },
    mounted () {
      // console.log(document.body.scrollHeight)
          console.log(this.prevRoute.path)

    setInterval(() => {
      this.scrollHeight = document.body.scrollHeight;
      // console.log(window.top.scrollY)

    }, 100);
      if (this.$route.hash === '#gallery') {
        if (this.isMobile) {
          window.scrollTo(0, 2275)
        } else if (this.isTablet) {
          window.scrollTo(0, 2460)
        } else {
          // this.prevRoute.path === '/about' ? window.scrollTo(0, 1933) : window.scrollTo(0, 1933)
          window.scrollTo(0, 1833)

        }
      } else if (this.$route.hash === '#news'){
        if (this.isMobile) {
          window.scrollTo(0, 1773)
        } else if (this.isTablet) {
          window.scrollTo(0, 1675)
        } else {
          // this.prevRoute.path === '/about' ? window.scrollTo(0, 1500) : window.scrollTo(0, 1500)
          window.scrollTo(0, 1400)
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' }) 
      }
    },
    methods: {
      checkH () {
        // console.log(window.top.scrollY)
      }
    }
  }
</script>

<style scoped>
/* .home-wrapper {
  margin-top: 149px;
} */
</style>
