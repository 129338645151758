<template>
    <div class="title-section" :class="isLight ? 'light-bg' : ''">
      <div class="title-text" :class="isMobile ? 'title-mobile' : ''">{{ title }}</div>
    </div>
</template>

<script>
export default {
  name: 'TitleSection',
  props: ['title', 'isLight'],
  computed: {
    isMobile () {
      return window.innerWidth < 960
    },
    isTablet () {
      return window.innerWidth < 1280
    },
  },
}
</script>

<style>
.title-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 20px;
  background-color: #121212;
  color: white;
  border-top: white solid 2px;
  border-bottom: white solid 2px;
}

.light-bg {
  background-color: white;
  color: black;
}

.title-text {
  font-family: 'Inter', sans-serif !important;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: .5px;
  text-transform: uppercase;
}

.title-mobile {
  font-size: 18px;
}

</style>