<template>
  <v-app>
    <Navbar />
    <v-main class="main-app">
      <router-view/>
    </v-main>
    <DoordashButton />
    <Footer />
    <FooterLogo />
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/FooterSection.vue'
import FooterLogo from '@/components/FooterLogo.vue'
import DoordashButton from '@/components/DoordashButton.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    FooterLogo,
    DoordashButton
  },
  data: () => ({
    //
  }),
};
</script>

<style>
.main-app {
  
}

#app {
  /* color: #4c3d3b !important; */
  font-family: 'Inter', sans-serif !important;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  border-left: white solid 2px;
  border-right: white solid 2px;
}

</style>