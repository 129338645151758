<template>
  <div class="news-section">
    <TitleSection :title="'Gallery'" />
    <vueper-slides
      autoplay
      infinite
      class="no-shadow"
      :visible-slides="isTablet ? 2 : 3"
      slide-multiple
      :gap="0"
      :slide-ratio="1 / 4"
      :dragging-distance="200"
      :bullets="false"
      :arrows="true"
      :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
      :fixed-height="isMobile ? '230px' : '450px'"
    >
      
      <vueper-slide v-for="(i, index) in news" :key="index" :image="i.image" />
      
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import image1 from '@/assets/foods/4S8A2519.jpg'
import image2 from '@/assets/foods/4S8A2499.jpg'
import image3 from '@/assets/foods/4S8A2615.jpg'

import image4 from '@/assets/foods/4S8A3603.jpg'
import image5 from '@/assets/foods/4S8A3635.jpg'
import image6 from '@/assets/foods/4S8A2754.jpg'

import image7 from '@/assets/foods/4S8A2774.jpg'
import image8 from '@/assets/foods/4S8A2809.jpg'
import image9 from '@/assets/foods/4S8A2960.jpg'
import TitleSection from '@/components/TitleSection.vue'
// 4S8A2519.jpg
// 4S8A2499.jpg
// 4S8A2615.jpg

// 4S8A3603.jpg
// 4S8A3635.jpg
// 4S8A2754.jpg

// 4S8A2774.jpg
// 4S8A2809.jpg
// 4S8A2960.jpg
export default {
  name: 'GallerySection',
  components: { 
    VueperSlides, 
    VueperSlide,
    TitleSection
  },
  computed: {
    isMobile () {
      return window.innerWidth < 600
    },
    isTablet () {
      return window.innerWidth < 1280
    },
  },
  data () {
    return {
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      news: [
        {
          image: image9,
        },
        {
          image: image2,
        },
        {
          image: image4,
        },
        {
          image: image5,
        },
        {
          image: image6,
        },
        {
          image: image3,
        },
        {
          image: image7,
        },
        {
          image: image1,
        },
        {
          image: image8,
        },
      ]
    }
  },
}
</script>

<style>
.vueperslide__content-wrapper:first-child {
  border-left: white solid 1px;
  border-right: white solid 1px;
  /* border-bottom: white solid 2px; */
  color: white;
}

.vueperslides--fixed-height.vueperslides--bullets-outside {
  margin: 0 !important;
}
.vueperslide__title {
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}
.vueperslide__image {
  opacity: 0.5;
  background-color: black;
}
.vueperslide vueperslide--active vueperslide--visible {

  opacity: 0.5;
  background-color: black;
}

  /* @media screen and (max-width: 599px) {
    .vueperslides--fixed-height { 
      height: 470px; 
    }
  }

  @media screen and (min-width: 600px) {
    .vueperslides--fixed-height { 
      height: 800px; 
    }
  } */

@media screen and (max-width: 1280px) {
  .news-wrapper {
    flex-wrap: wrap;
  }
  .news-content {
    flex: 0 0 50%;
  }
}
</style>