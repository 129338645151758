<template>
  <div class="intro-wrapper">
    <div class="intro-content">

      <img data-aos="fade-right" data-aos-delay="50" src="@/assets/intro-logo.svg" alt="" width="120px">
      <div class="intro-text mt-5">
        <div data-aos="fade-right" data-aos-delay="300" data-aos-easing="linear" data-aos-duration="250">
          Kazoku means Family in Japanese and we believe in the power of simple elegance and the comfort of home for our families where we can all savor the flavors of traditional Hibachi and other Japanese dishes.
        </div>
        <br>
        <div data-aos="fade-right" data-aos-delay="500" data-aos-easing="linear" data-aos-duration="250">
          Experience the Best of Japanese Hibachi in Jonesboro, Arkansas. 
        </div>
        <br>
        <div data-aos="fade-right" data-aos-delay="700" data-aos-easing="linear" data-aos-duration="250">
          At Kazoku Hibachi Express, we believe in the power of simple elegance and the comfort of home. Located at 2411 Race St. Suite A, our restaurant offers an inviting atmosphere where you can savor the flavors of traditional Hibachi dishes. 
        </div>
        <br>
        <div data-aos="fade-right" data-aos-delay="900" data-aos-easing="linear" data-aos-duration="250">
          Indulge in our hibachi specialties, prepared by our skilled chefs who bring both culinary expertise and a touch of entertainment to your table. Our diverse menu offers an array of delicious options, including vegetarian and gluten-free choices, ensuring there's something for everyone.
           <!-- If you're a  enthusiast, you'll be delighted by our flavorful and comforting noodle bowls that will transport you straight to the streets of Japan. -->
        </div>

      </div>

      <div class="button-wrapper mt-10" v-if="!isNotDesktop" data-aos="fade-right" data-aos-delay="1200" data-aos-easing="linear" data-aos-duration="250">
        <CustomButton 
          text="EXPLORE MENU"
          :isMenu="true"
        />
        <CustomButton 
          class="ml-3"
          text="DOORDASH ORDER"
          :link="'https://www.doordash.com/store/kazoku-hibachi-express-jonesboro-24912729/'"
          :isMenu="true"
        />
      </div>
    </div>

  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
// import menu from '@/assets/menu.pdf'

export default {
  name: 'HomeIntro',
  data () {
    return {
      // menu
    }
  },
  computed: {
    isNotDesktop () {
      return window.innerWidth < 1025
    },
  },
  components: {
    CustomButton
  }
}
</script>

<style>
.intro-wrapper {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.intro-content {
  margin: 6rem 0;
  max-width: 900px;
}
.button-wrapper {
  display: flex; 
}


@media screen and (min-width: 600px) {
  .intro-wrapper {
    padding: 0 27px;
  }
  .intro-content {
    /* max-width: 100%; */
  }
}

@media screen and (max-width: 599px) {
  .intro-wrapper {
    padding: 0 56px;
  }

  .intro-content {
    max-width: 100%;
  }
}
</style>