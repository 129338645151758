<template>
  <v-btn
    v-if="!isMobile"
    fab
    large
    right
    dark
    bottom
    fixed
    color=""
    class="m-4"
    @click="handleClick"
  >
    <img src="@/assets/doordash-2.png" alt="" width="80px" />
  </v-btn>
</template>

<script>
export default {
  name: "DoordashButton",

  data() {
    return {};
  },

  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
  },

  methods: {
    handleClick() {
      const url =
        "https://www.doordash.com/store/kazoku-hibachi-express-jonesboro-24912729/";
      window.open(url);
    },
  },
};
</script>
